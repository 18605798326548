import React from "react"
import { Parallax } from 'react-scroll-parallax'

import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const PaymentMethods = () => {
  const data = useStaticQuery(graphql`
    query PaymentImageQuery {
      file(relativePath: {eq: "PaymentsMethods.png"}) {
        childImageSharp {
          fluid {
            base64
            src
            aspectRatio
          }
        }
      }
    }
  `)

  return (
    <Parallax className="page page--methods" y={[0, 0]}>
      <div className="page__content">
        <div className="content__column-text">
          <h2>As principais formas de pagamento</h2>
        </div>

        <div className="content__column-image">
          <Img
            fluid={data.file.childImageSharp.fluid}
            alt="As principais formas de pagamento" />
        </div>
      </div>
    </Parallax>
  )
}

export default PaymentMethods
